<template>
    <div class="settings-add--container">
<!--      <div class="select-type&#45;&#45;container">-->
<!--        <switch-graphic v-model="type" :selected-value="type"></switch-graphic>-->
<!--      </div>-->
      <p class="title--container title__text" v-if="type === 0">Добавление класса</p>
      <p class="title--container title__text" v-if="type === 1">Добавление группы</p>
      <p class="text--container text">Введите данные с другого индивидуального ключа</p>
        <alert-text class="alert--container" :value="alertText" v-if="alertText" :success="isSuccess"></alert-text>
        <div class="add--form-container">
            <profile-input class="profile__input" size="md" placeholder="логин" v-model="login"></profile-input>
            <profile-input class="profile__input" size="md" placeholder="пароль" v-model="password"></profile-input>
          <div class="class--input-container" v-if="type === 0">
            <p>Класс</p>
            <profile-input class="class__input" size="xs" text-class="text-center" v-model="digit" placeholder="1"></profile-input>
            <profile-input class="class__input" size="xs" text-class="text-center" v-model="letter" placeholder="А"></profile-input>
          </div>
          <div class="class--input-container" v-if="type === 1">
            <p>Группа</p>
            <profile-input class="class__input" size="xs" text-class="text-center" v-model="digit" placeholder="1"></profile-input>
          </div>
            <div class="controls--container">
                <standart-button value="Отправить на проверку" style-button="standart-button" @click.native="unionAccounts"></standart-button>
                <a @click="backToSettings">Отмена</a>
            </div>
        </div>
        <warning-union-accounts-modal v-on:confirm="onConfirmUnion"></warning-union-accounts-modal>
    </div>
</template>

<script>

    import AlertText from "../../components/alert/AlertText";
    import StandartButton from "../../components/button/StandartButton";
    import ProfileInput from "../../components/input/ProfileInput";
    import WarningUnionAccountsModal from "../../components/modal/WarningUnionAccountsModal";

    export default {
        name: "SettingsClassesAddView",
        components: { WarningUnionAccountsModal, StandartButton, ProfileInput, AlertText},
        data(){
            return {
                login : "",
                password : "",
                letter: "",
                digit: "",
                alertText : "",
                isSuccess : false,
              type: null

            }
        },
        computed:{
            profile(){
                return this.$store.getters.PROFILE
            }
        },
        methods:{
            async onConfirmUnion(){
                const statusCode = await this.$store.dispatch("UNION_ACCOUNTS",{data : {
                        login : this.login,
                        password : this.password,
                        letter : this.letter,
                        digit : this.digit
                    }})


                if (statusCode === 200){
                    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")

                    this.isSuccess = true;
                    this.alertText = "Операция завершена успешно"
                    setTimeout(() => {
                        this.backToSettings()
                    },1000)
                } else {
                    if (statusCode === 403){
                        this.alertText = "Нельзя добавить аккаунт с таким статусом"
                    } else if (statusCode === 400) {
                        this.alertText = "Этот класс уже занесен в ваш профиль"
                    } else{
                        this.alertText = "Неправильный логин/пароль или класс"
                    }
                }
            },


            async unionAccounts(){
                this.isSuccess = false
                if (this.login.length === 0){
                    this.alertText = "Введите корректный логин"
                } else if (this.password.length < 6 ){
                    this.alertText = "Введите корректный пароль"
                } else if ((this.letter.length === 0 || this.digit.length === 0) && this.type === 0){
                    this.alertText = "Введите корректный класс"
                } else {
                    this.$modal.show("WarningUnionAccountsModal")
                }
            },


            backToSettings(){
                this.$router.push({name : "SettingsClassesView"})
            }
        },
        async mounted(){
            if (this.profile.payStatus === 0){
                this.$router.go(-1)
            }
          this.type = this.profile.myClasses[0].type

        }

    }
</script>

<style scoped lang="scss">
    .settings-add--container{
        width: 335px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        margin-top: 19px;

        .title--container{

        }

        .title__text{
            font-family: Roboto, "sans-serif";
            font-size: 28px;
            color: #222222;
            letter-spacing: 0;
            line-height: 33px;
        }


        .text--container{
            margin-top: 13px;

        }

        .text{
            font-family: Roboto, "sans-serif";
            letter-spacing: 0;
            font-size: 15px;
            line-height: 20px;
            color: #222222;
        }

        .alert--container{
            margin-top: 14px;
            display: flex;
            width: 100%;
            justify-content: center;
        }

        .add--form-container{
            display: flex;
            flex-direction: column;
            margin-top: 10px;

            .profile__input{
                margin-top: 10px;
            }

            .class--input-container{
                margin-top: 10px;
                display: flex;
                align-items: center;
                height: 46px;

                p{
                    font-family: Roboto, "sans-serif";
                    font-size: 15px;
                    color: #222222;
                    letter-spacing: 0;
                    line-height: 18px;
                    margin-right: 15px;
                    margin-left: 10px;
                }

                .class__input{
                    margin-left: 22px;
                }
            }


            .controls--container{
                margin-top: 61px;
                display: flex;
                flex-direction: column;
                width: 327px;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;

                a{
                    margin-top: 25px;
                    font-family: Roboto, "sans-serif";
                    font-size: 13px;
                    letter-spacing: 0px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
